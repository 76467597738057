import React, { useState, useEffect, useContext } from 'react';
import { CartContext } from './CardContext';
import './Product.css';
import axios from 'axios';
import { BASE_URL } from './helper'; // Adjust the import path as needed


const formatPKR = (amount) => {
  return `Rs ${amount.toFixed(2)}`;
};

const ProductPage = ({ product }) => {
  const { addToCart } = useContext(CartContext);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState({ name: '', email: '', rating: 0, text: '' });
  const [activeTab, setActiveTab] = useState(null);
  const [selectedRating, setSelectedRating] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/reviews`);
        setReviews(response.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, []);

  const handleImageChange = (index) => {
    setCurrentImageIndex(index);
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setNewReview(prevState => ({ ...prevState, [name]: value }));
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    try {
      const reviewData = {
        name: newReview.name,
        email: newReview.email,
        rating: selectedRating,
        text: newReview.text,
      };

      const response = await axios.post(`${BASE_URL}/api/reviews`, reviewData);
      setReviews([...reviews, { ...response.data, pending: true }]);
      setNewReview({ name: '', email: '', rating: 0, text: '' });
      setSelectedRating(0);
      setSuccessMessage('Review submitted successfully and is awaiting approval.');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (error) {
      console.error('Error submitting review:', error);
      if (error.response) {
        console.error('Server error:', error.response.data);
      } else {
        console.error('Network error:', error.message);
      }
    }
  };

  const handleRatingSelect = (rating) => {
    setSelectedRating(rating);
  };

  const handleAddToCart = () => {
    addToCart({ ...product, quantity });
    setSuccessMessage('Item has been successfully added to the cart.');
    setTimeout(() => {
      setSuccessMessage('');
    }, 2000);
  };


  const handleTouchStart = (e) => {
    const touchStartX = e.touches[0].clientX;
    const handleTouchMove = (e) => {
      const touchEndX = e.touches[0].clientX;
      if (touchStartX - touchEndX > 50) {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.images.length);
        document.removeEventListener('touchmove', handleTouchMove);
      } else if (touchEndX - touchStartX > 50) {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + product.images.length) % product.images.length);
        document.removeEventListener('touchmove', handleTouchMove);
      }
    };
    document.addEventListener('touchmove', handleTouchMove);
  };

  return (
    <div className="product-page">
      <div className="product-section">
        {successMessage && <div className="success-message">{successMessage}</div>}
        <div className="product-main">
          <div className="carousel1" onTouchStart={handleTouchStart}>
            <div className="carousel-images1">
              {product.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Product image ${index + 1}`}
                  className={`carousel-image1 ${index === currentImageIndex ? 'active' : ''}`}
                />
              ))}
            </div>
            <div className="carousel-indicators1">
              {product.images.map((_, index) => (
                <span
                  key={index}
                  className={`indicator1 ${index === currentImageIndex ? 'active' : ''}`}
                  onClick={() => handleImageChange(index)}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="product-details">
          <h1 className="product-title">{product.title}</h1>
          <p className="product-price">{formatPKR(product.price)}</p>
          <p className="product-description">{product.description}</p>
          <div className="product-actions">
            <input
              type="number"
              min="1"
              value={quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
              className="quantity-input"
            />
            <button className="add-to-cart" onClick={handleAddToCart}>Add to cart</button>
          </div>
          <p className="product-category">Category: {product.category}</p>
        </div>
      </div>

      <div className="product-tabs">
        <button
          className={`tab-button ${activeTab === 'reviews' ? 'active' : ''}`}
          onClick={() => setActiveTab('reviews')}
        >
          Reviews
        </button>
        <button
          className={`tab-button ${activeTab === 'info' ? 'active' : ''}`}
          onClick={() => setActiveTab('info')}
        >
          Additional Information
        </button>
      </div>
      {activeTab === 'reviews' && (
        <div className="product-reviews">
          <h2>Reviews ({reviews.length})</h2>
          {reviews.length === 0 && <p>No reviews yet.</p>}
          {reviews.map((review, index) => (
            <div key={index} className="review">
              <div className="review-header">
                <span className="review-name">{review.name}</span>
                <span className="review-rating">{'★'.repeat(review.rating)}</span>
              </div>
              <p className="review-text">{review.text}</p>
              {review.pending && <p className="review-pending">Your review is awaiting approval</p>}
            </div>
          ))}
          <div className="add-review">
            <h3>Add a review</h3>
            <form onSubmit={handleReviewSubmit} className="review-form">
              <label>
                Your rating *
                <div className="rating-stars">
                  {[1, 2, 3, 4, 5].map(star => (
                    <span
                      key={star}
                      className={`star ${selectedRating >= star ? 'selected' : ''}`}
                      onClick={() => handleRatingSelect(star)}
                    >
                      ★
                    </span>
                  ))}
                </div>
              </label>
              <label>
                Your review *
                <textarea
                  name="text"
                  value={newReview.text}
                  onChange={handleReviewChange}
                  required
                  className="review-textarea"
                />
              </label>
              <label>
                Name *
                <input
                  type="text"
                  name="name"
                  value={newReview.name}
                  onChange={handleReviewChange}
                  required
                  className="small-input name-input"
                />
              </label>
              <label>
                Email *
                <input
                  type="email"
                  name="email"
                  value={newReview.email}
                  onChange={handleReviewChange}
                  required
                  className="small-input email-input"
                />
              </label>
              <button type="submit" className="small-button">Submit</button>
            </form>
          </div>
        </div>
      )}
      {activeTab === 'info' && (
        <div className="additional-info">
          <h2>Additional Information</h2>
          <table>
            <tbody>
              <tr>
                <td>Weight</td>
                <td>{product.weight}</td>
              </tr>
              <tr>
                <td>Size</td>
                <td>{product.size}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProductPage;
