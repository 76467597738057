import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Contact from './Contact';
import Newsletter from './Newsletter';
import Footer from './Footer';
import Benefits from './Benefits';
import AboutUs from './AboutUs';
import ProductPage from './Product';
import ShippingPolicy from './shippingpolicy';
import { CartProvider } from './CardContext';
import Checkout from './Checkout';
import HowtoUse from './HowtoUse';
import ViewCartPage from './ViewCartPage';
import PrivacyPolicy from './PrivacyPolicy';


const product = {
  images: [
    '/Productimage2.webp',
    '/Productimage1.webp',
    '/Productimage3.webp',
    '/Productimage4.webp',
  ],
  title: 'Dried Kasuri Methi',
  price: 200,
  description: 'Dried Kasuri Methi is renowned for their exceptional quality, enhancing the flavor of your culinary creations with their unique, slightly bitter taste. Perfect for a variety of dishes, from curries to vegetables, our Kasuri Methi is sourced and processed with utmost care to retain its natural essence and purity. Elevate your cooking with this exquisite, organic herb, now conveniently available for purchase.',
  category: 'Herbs',
  size: '10*10 inches',
  weight: '80 gm | 2.8 oz'
};


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <CartProvider>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/pages/benefits" element={<Benefits />} />
          <Route path="/products" element={<ProductPage product={product} />} />
          <Route path="/pages/how-to-use" element={<HowtoUse />} />
          <Route path="/pages/history" element={<History />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/shippingpolicy" element={<ShippingPolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/viewcart" element={<ViewCartPage />} />
        </Routes>
        <Newsletter />
        <Footer />
      </Router>
    </CartProvider>
  );
};

export default App;
