import React from 'react';
import "./PrivacyPolicy.css"

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy-container">
            <h2>Privacy Policy</h2>
            <p>At Sam Organic Co. We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.samorganic.co and interact with our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</p>
            
            <div className="policy-paragraph">
                <h3>Information We Collect</h3>
                <p>We may collect information about you in a variety of ways. The information we may collect on the site includes:</p>
                <h4>Personal Data</h4>
                <p>Personally identifiable information, such as your email address, that you voluntarily give to us when you register with the site, place an order, or subscribe to our newsletters.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>How We Use Your Information</h3>
                <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the site to:</p>
                <ul>
                    <li>Inform you about promotions, discounts, and other updates.</li>
                    <li>Process your orders and manage your account.</li>
                    <li>Improve our services and customer experience.</li>
                </ul>
            </div>
            
            <div className="policy-paragraph">
                <h3>Disclosure of Your Information</h3>
                <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
                <h4>By Law or to Protect Rights</h4>
                <p>If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</p>
                <h4>Third-Party Service Providers</h4>
                <p>We may share your information with third-party service providers that perform services for us or on our behalf, such as shipping companies.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>Data Security</h3>
                <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>Data Retention</h3>
                <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Policy, unless a longer retention period is required or permitted by law. We typically retain your personal data for six months.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>Cookies and Tracking Technologies</h3>
                <p>We do not use cookies or other tracking technologies on our site.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>Third-Party Links</h3>
                <p>Our website does not contain links to third-party sites.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>Children’s Privacy</h3>
                <p>We do not knowingly collect personal information from children under the age of 13. If we learn that we have collected personal information from a child under the age of 13, we will delete that information as quickly as possible.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>Changes to This Privacy Policy</h3>
                <p>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. We will notify you of any changes by email.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>Contact Us</h3>
                <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
                <ul>
                    <li>Email: <a href="mailto:info@samorganic.co">info@samorganic.co</a></li>
                    <li>Phone: 03186000209</li>
                    <li>Address: Shop No. 54, Ayesha Sadeeqa Market, Railway Road, Kasur</li>
                </ul>
            </div>
            
            <p>Thank you for choosing Sam Organic Co. We are dedicated to protecting your privacy and providing you with the best service possible.</p>
        </div>
    );
};

export default PrivacyPolicy;