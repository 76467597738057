import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-text">
        <span>Flat 10% off on Direct Bank Transfer!</span>
        <span>Flat 10% off on Direct Bank Transfer!</span>
        <span>Flat 10% off on Direct Bank Transfer!</span>
        <span>Flat 10% off on Direct Bank Transfer!</span>
        <span>Flat 10% off on Direct Bank Transfer!</span>
        <span>Flat 10% off on Direct Bank Transfer!</span>
        <span>Flat 10% off on Direct Bank Transfer!</span>
        <span>Flat 10% off on Direct Bank Transfer!</span>
      </div>
    </div>
  );
};

export default Banner;
