// src/Home.js
import React from 'react';
import './Home.css';
import Card from './Card';
import { Link } from 'react-router-dom';



const Home = () => {
  return (
    <div className="home">
      <div className="section-home">
        <div className="home-content">
          <h2>Natural & Organic</h2>
          <h1>Dried <span className='black-text light-font'>Fenugreek</span> Leaves (Kasuri Methi)</h1>
          <p>
          Kasuri Methi, is a culinary gem, transforming curries, dals, and vegetables into exquisite gourmet experiences.
          </p>
          <div className="home-buttons">
            <Link to="/products" className="shop-button">Shop Now</Link>
            <Link to="/contact"  className="contact-button">Contact Us</Link>
          </div>
          <div className="home-image2">
            <img src="/Methi.webp" alt="Kasuri Methi" />
          </div>
        </div>
        <div className="home-image">
          <img src="/Methi.webp" alt="Kasuri Methi" />
        </div>
      </div>
      <div className="benefits-container">
        <Card iconSrc="/Natural.png" text="100% Natural" />
        <Card iconSrc="/LowSugar.png" text="Low Sugar" />
        <Card iconSrc="/Breast.png" text="Lactation Enhancement" />
      </div>
      <div className="hero-section">
        <div className="hero-content">
          <img src="/Methi.webp" alt="Product Image" className="hero-image" />
          <div className="hero-text">
            <h1>About <span className="highlight">Our Company</span></h1>
            <p>Sam Organic. Co, formally established in 2024, has been a trusted name in organic products for years. Known for its premium kasuri methi, or dried fenugreek leaves, the company ensures top-notch quality and purity. </p>
            <p>With export orders now in process, Sam Organic Co. is expanding its reach globally. Their dedication to sustainability and organic farming practices reflects their commitment to both the environment and customer health, making them a leader in the organic spice market.</p>
            <Link to="/contact" className="shop-now">Contact Us</Link>
          </div>
        </div>
      </div>
      <div className="testimonials-section">
        <h2>What clients are saying!</h2>
        <div className="testimonials-container">
          <div className="testimonial">
            <p>
            As a doctor, I appreciate effort for promoting Kasuri Methi as It's a healthy, flavorful addition to meals, offering natural benefits <span className="highlight highlight-1">without-additives</span>.  Ideal for those seeking both taste and wellness in their diet.
            </p>
            <div className="testimonial-author">
              <img src="/patient.webp" alt="Ava Bennett" className="testimonial-author-image" />
              <p>Dr. Ali</p>
            </div>
          </div>
          <div className="testimonial">
            <p>
            The packaging keeps it safe and keep its flavour and colour intact It's a  for anyone who loves <span className="highlight highlight-2">authentic and rich flavors</span> in their cooking. Highly recommended for its superior quality and value for money!
            </p>
            <div className="testimonial-author">
              <img src="/patient.webp" alt="Rose Mitchell" className="testimonial-author-image" />
              <p>Ali Kashif</p>
            </div>
          </div>
          <div className="testimonial">
            <p>
            I recently tried Kasuri Methi, and it has become a staple in my kitchen. The dried fenugreek leaves add a unique, <span className="highlight highlight-3">aromatic flavor</span> to my cooking. It's easy to use and blends well with various dishes, from curries to breads. 
            </p>
            <div className="testimonial-author">
              <img src="/patient.webp" alt="Daniel Bennett" className="testimonial-author-image" />
              <p>M. Atif Arif</p>
            </div>
          </div>
        </div>
      </div>
      <div className="services-container">
        <div className="service">
          <img src="/Dollar.png" alt="Money Guarantee" className="service-icon" />
          <h4>Money Guarantee</h4>
          <p>Within 30 days for an exchange.</p>
        </div>
        <div className="service">
          <img src="/support.png" alt="Online Support" className="service-icon" />
          <h4>Online Support</h4>
          <p>24 hours a day, 7 days a week</p>
        </div>
        <div className="service">
          <img src="/credit.png" alt="Flexible Payment" className="service-icon" />
          <h4>Flexible Payment</h4>
          <p>Pay with Multiple Credit Cards</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
