import React from 'react';
import './shippingpolicy.css'; // Import CSS file for styling

const ShippingPolicy = () => {
    return (
        <div className="shipping-policy-container">
            <h2>Shipping Policy</h2>
            <p>Welcome to Sam Organic Co. We are committed to providing you with high-quality products and a smooth shopping experience. Please review our shipping policy below for details on our shipping practices.</p>
            
            <div className="policy-paragraph">
                <h3>1. Shipping Destinations</h3>
                <p>We offer shipping both domestically and internationally.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>2. Shipping Rates & Delivery Times</h3>
                <p><strong>Local Shipping:</strong></p>
                <p>Rates: RS.200</p>
                <p>Delivery Time: 2-3 working days</p>
                <p><strong>International Shipping:</strong></p>
                <p>Rates: varies by destination</p>
                <p>Delivery Time: Varies by destination</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>3. Processing Time</h3>
                <p>Orders are processed within 1 business day before being shipped.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>4. Shipping Carriers</h3>
                <p>We use TCS and M&P for our shipping needs.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>5. Order Tracking</h3>
                <p>Once your order has shipped, you will receive an email with the courier company and tracking ID. You can use this information to track your order.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>6. International Shipping</h3>
                <p>International orders may be subject to customs fees and import taxes, which are the responsibility of the customer.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>7. Shipping Restrictions</h3>
                <p>We currently do not have specific shipping restrictions on products or regions.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>8. Handling Returns and Exchanges</h3>
                <p>We do not accept returns or exchanges. All sales are final.</p>
            </div>
            
            <div className="policy-paragraph">
                <h3>9. Contact Information</h3>
                <p>For any shipping-related inquiries, please contact us at <a href="mailto:info@samorganic.co">info@samorganic.co</a>.</p>
            </div>
            
            <p>Thank you for shopping with Sam Organic Co.!</p>
        </div>
    );
};

export default ShippingPolicy;
