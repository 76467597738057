// src/Benefits.js
import React from 'react';
import Card from './Card';
import BenefitItem from './BenefitItem';
import './Benefits.css';

const Benefits = () => {
  return (
    <div>
      <div className="section-benefits">
        <h1>Benefits</h1>
      </div>
      <div className="benefits-container">
        <Card iconSrc="/Natural.png" text="100% Natural" />
        <Card iconSrc="/LowSugar.png" text="Low Sugar" />
        <Card iconSrc="/Breast.png" text="Lactation Enhancement" />
      </div>
      <div className="new-benefits-section">
        <h2>
          Best Benefits Of Our <span className="highlight">Dried</span> <br />
          <span className="highlight">Kasuri Methi</span>
        </h2>
        <p>Kasuri methi is a natural and organic source of essential nutrients, beneficial for health and wellness.</p>
        <div className="benefits-content">
          <div className="benefits-column">
            <BenefitItem title="Natural & Organic" description="Kasuri methi is a natural and organic source of essential nutrients, beneficial for health and wellness." />
            <BenefitItem title="Micro Nutrients" description= "Drying fenugreek leaves removes vitamin C but retains calcium and iron, crucial for strong bones and hemoglobin." />
            <BenefitItem title="Control Diabetes" description= "Helps control glucose levels and improves insulin function and carbohydrate tolerance in type II diabetics due to its fiber content." />
          </div>
          <div className="benefits-image">
            <img src="/Methi.webp" alt="Methi" />
          </div>
          <div className="benefits-column">
            <BenefitItem title="High In Fibre" description="Rich in fiber, boosts metabolism, aids fat burning, and alleviates constipation by adding bulk to food and stool." />
            <BenefitItem title="Prevents Skin Diseases" description="Rich in antioxidants and nutrients, prevents skin damage, reduces blemishes, and provides a natural glow." />
            <BenefitItem title="Reduces Hair Fall" description= "Removes dead skin cells, unclogs hair follicles, and enhances nutrient absorption, promoting healthy, strong hair." />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
