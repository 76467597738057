import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import './BenefitItem.css';

const BenefitItem = ({ title, description }) => {
  return (
    <div className="benefit-item">
      <div className="custom-tick-btn">
        <FontAwesomeIcon icon={faCheck} className="fa-2x text-primary" />
      </div>
      <div className="benefit-text">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default BenefitItem;
