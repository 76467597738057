// src/AboutUs.js
import React from 'react';
import './AboutUs.css';
import { Link } from 'react-router-dom';



const AboutUs = () => {
  return (

    <div>
      <div className="section-aboutus">
        <h1>About Us</h1>
      </div>
      <div className="hero-section">
        <div className="hero-content">
          <img src="/Methi.webp" alt="Product Image" className="hero-image" />
          <div className="hero-text">
            <h1>About <span className="highlight">Our Company</span></h1>
            <p>Sam Organic. Co, formally established in 2024, has been a trusted name in organic products for years. Known for its premium kasuri methi, or dried fenugreek leaves, the company ensures top-notch quality and purity. </p>
            <p>With export orders now in process, Sam Organic Co. is expanding its reach globally. Their dedication to sustainability and organic farming practices reflects their commitment to both the environment and customer health, making them a leader in the organic spice market.</p>
            <Link to="/contact" className="contact-now">Contact Us</Link>
          </div>
        </div>
      </div>
      <hr /> {/* Horizontal line */}
      <div className="about-container">
        <div className="about-section">
          <div className="about-heading">Commitment to Quality</div>
          <div className="about-content">
            <div className="about-paragraph">
              <p>
                At Sam Organic.co, our commitment to quality is unwavering. We start with the finest fenugreek seeds, grown sustainably and dried naturally, without any preservatives. Unlike others, we deliver 100% pure, high-quality organic Kasuri Methi. Our meticulous process ensures the freshest, most flavorful leaves, bringing authentic taste to every dish.
              </p>
            </div>
            <div className="about-image">
              <img src="./culinary.jpg" alt="Commitment to Quality" />
            </div>
          </div>
        </div>

        <div className="about-section">
          <div className="about-heading">Global Culinary Staple</div>
          <div className="about-content">
            <div className="about-paragraph">
              <p>
                At Sam Organic.co, our vision is to transform Kasuri Methi from a cherished South Asian delicacy into a global culinary staple. We are dedicated to sharing the authentic taste and superior quality of our organic fenugreek leaves with kitchens around the world. We ensure that every leaf retains its rich flavor and nutritional value. Through our commitment to excellence, we aim to introduce the world to the unparalleled taste of pure, high-quality Kasuri Methi, elevating dishes and enriching culinary experiences everywhere.
              </p>
            </div>
            <div className="about-image">
              <img src="./culinary1.jpg" alt="Global Culinary Staple" />
            </div>
          </div>
        </div>

        <div className="about-section">
          <div className="about-heading">Sustainable, Natural, Exceptional</div>
          <div className="about-content">
            <div className="about-paragraph">
              <p>
                At Sam Organic.co, located in Nizampura Kasur - the origin and main hub of Kasuri Methi - we uphold the principles of sustainability, natural purity, and exceptional quality. Our commitment to sustainability begins with eco-friendly farming practices that nurture the earth and ensure the longevity of our crops. We maintain the natural integrity of our fenugreek leaves through a meticulous drying process free from preservatives and additives. The result is an exceptional product: 100% pure, organic Kasuri Methi that offers unmatched flavor and nutritional value. By prioritizing sustainability and natural methods, we deliver a product that stands out in quality and authenticity, ready to elevate dishes around the world.
              </p>
            </div>
            <div className="about-image">
              <img src="sustainable.jpeg" alt="Sustainable, Natural, Exceptional" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
