import React, { useState } from 'react';
import './HowtoUse.css';
import Card from './Card';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Carousel } from 'react-responsive-carousel';


const HowtoUse = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const slides = [
        {
            iconSrc: "/butter.webp",
            title: "Butter Chicken",
            description: "Kasuri methi enhances the creamy, tomato-based gravy with its earthy flavor."
        },
        {
            iconSrc: "/daal.webp",
            title: "Daal Makhni",
            description: " A slow-cooked lentil dish flavored with butter, cream, and kasuri methi for added depth."
        },
        {
            iconSrc: "/aloo.webp",
            title: "Aloo Methi",
            description: " A dry curry made with potatoes and fresh methi leaves, but adding kasuri methi gives a more intense flavor."
        }
    ];

    return (
        <div>
            <div className="section-howtouse">
                <h1>How to Use</h1>
            </div>
            <div className="benefits-container">
                <Card iconSrc="/Natural.png" text="100% Natural" />
                <Card iconSrc="/LowSugar.png" text="Low Sugar" />
                <Card iconSrc="/Breast.png" text="Lactation Enhancement" />
            </div>
            <div className="section-usage">
                <div className="content">
                    <h1>How to Use <span className="highlight">Natural &</span><br />
                        <span className="highlight">Organic</span> Kasuri Methi</h1>
                    <p>Kasuri methi (dried fenugreek leaves) adds a unique aroma and flavor to many dishes. Here are some popular dishes you can make using kasuri methi:</p>
                    <Carousel
                        showArrows={true}
                        autoPlay={true}
                        infiniteLoop={true}
                        showThumbs={false}
                        showStatus={false}
                        dynamicHeight={true}
                        showIndicators={false}
                    >
                        {slides.map((slide, index) => (
                            <div key={index} className="carousel-item">
                                <div className="icon">
                                    <img src={slide.iconSrc} alt={`icon${index + 1}`} style={{ width: '100%', height: '500px', objectFit: "cover" }} />
                                </div>
                                <h2>{slide.title}</h2>
                                <p>{slide.description}</p>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default HowtoUse;
