// Newsletter.js

import React from 'react';
import { useState } from 'react'; // Import useState hook
import './Newsletter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'; // Import faPaperPlane icon

const Newsletter = () => {
    const [hovered, setHovered] = useState(false); // State for tracking hover state
    const [email, setEmail] = useState(''); // State for email input


    const handleHover = () => {
        setHovered(true);
    }

    const handleMouseLeave = () => {
        setHovered(false);
    }

    const handleSendClick = () => {
        setTimeout(() => {
            setEmail('');
        }, 2000);
    }

    return (
        <div className="newsletter-container">
            <div className="newsletter-overlay"></div>
            <div className="container">
                <div className="mx-auto text-center">
                    <h1 className="newsletter-title">
                        <span className="subscribe-text">Let's Subscribe</span> <span className="newsletter-name">The Newsletter</span>
                    </h1>
                    <p className="newsletter-description">Subscribe now to get latest updates on our products</p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-7">
                        <div className="newsletter-input-container">
                            <input className="newsletter-input" type="text" placeholder="Enter Your Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            <div className={`newsletter-send-icon ${hovered ? 'hovered' : ''}`} onMouseEnter={handleHover} onMouseLeave={handleMouseLeave} onClick={handleSendClick}>
                                <FontAwesomeIcon icon={faPaperPlane} className="newsletter-icon" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;
