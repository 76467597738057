import React, { useContext, useState } from 'react';
import { CartContext } from './CardContext';
import './Checkout.css';
import axios from 'axios';
import Banner from './Banner';
import { BASE_URL } from './helper';
import { useNavigate } from 'react-router-dom';


const formatPKR = (amount) => {
  // You can use a library like react-intl for more robust formatting
  return `PKR ${amount.toFixed(2)}`;
};

const DELIVERY_CHARGES = 200;


const Checkout = () => {
  const { cart, clearCart } = useContext(CartContext);
  const [billingInfo, setBillingInfo] = useState({
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
  });
  const navigate = useNavigate();

  const [coupon, setCoupon] = useState('');
  const [discount, setDiscount] = useState(0);
  const [couponMessage, setCouponMessage] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('cashOnDelivery');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setBillingInfo({
      ...billingInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleCouponChange = (e) => {
    setCoupon(e.target.value);
    setDiscount(0); // Reset discount when coupon code changes
    setCouponMessage(''); // Reset coupon message when coupon code changes

  };

  const handleCouponApply = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/coupons/${coupon}`);
      const { discount } = response.data;
      setDiscount(discount / 100); // Set discount as a decimal value
      setCouponMessage('Coupon applied successfully!');
    } catch (error) {
      setDiscount(0);
      setCouponMessage('Invalid coupon code.');
      console.error('Error applying coupon:', error);
    }
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const cartTotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const additionalDiscount = paymentMethod === 'directBankTransfer' ? 0.1 : 0;
    const discountedTotal = cartTotal * (1 - discount - additionalDiscount) + DELIVERY_CHARGES;
    const orderDetails = {
      ...billingInfo,
      productName: cart[0].title,
      price: cart[0].price,
      quantity: cart[0].quantity,
      subtotal: cartTotal,
      total: discountedTotal,
      status: 'Pending',
      payment_method: paymentMethod
    };
    try {
      await axios.post(`${BASE_URL}/api/orders`, orderDetails);
      alert('Order placed successfully');
      navigate('/products');
      clearCart();
    } catch (error) {
      alert('Error placing order');
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const cartTotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const additionalDiscount = paymentMethod === 'directBankTransfer' ? 0.1 : 0;
  const discountedTotal = cartTotal * (1 - discount - additionalDiscount);
  const totalWithDelivery = discountedTotal + DELIVERY_CHARGES;

  return (
    <div className={`checkout-container ${isLoading ? 'loading' : ''}`}>
      <Banner />
      <h1 className="checkout-header">Checkout</h1>
      <div className="checkout-content">
        <form onSubmit={handleSubmit} className="checkout-form">
          <div className="checkout-section">
            <h2 className="section-title">Contact information</h2>
            <input
              type="email"
              name="email"
              placeholder="Email address"
              value={billingInfo.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="checkout-section">
            <h2 className="section-title">Billing address</h2>
            <input
              type="text"
              name="firstName"
              placeholder="First name"
              value={billingInfo.firstName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last name"
              value={billingInfo.lastName}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={billingInfo.address}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={billingInfo.city}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              value={billingInfo.state}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="zip"
              placeholder="ZIP Code"
              value={billingInfo.zip}
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone (optional)"
              value={billingInfo.phone}
              onChange={handleChange}
            />
          </div>
          <div className="checkout-section">
            <h2 className="section-title">Payment options</h2>
            <div className="payment-card">
              <input
                type='radio'
                name='paymentMethod'
                value="cashOnDelivery"
                checked={paymentMethod === 'cashOnDelivery'}
                onChange={handlePaymentMethodChange}
              />
              <label>Cash on Delivery</label>
            </div>
            <div className="payment-card">
              <input
                type="radio"
                name="paymentMethod"
                value="directBankTransfer"
                checked={paymentMethod === 'directBankTransfer'}
                onChange={handlePaymentMethodChange}
              />
              <label>Direct Bank Transfer</label>
            </div>
            {paymentMethod === 'directBankTransfer' && (
              <div className="payment-details">
                <p>Flat 10% off with Online Payment (Share ScreenShot of Payment with us) </p>
                <p>Bank Details:</p>
                <p>Bank Name: Bank Al Habib</p>
                <p>Account Name: Ammad Aslam</p>
                <p>Account Number: 00380981010697018</p>
                <p>IBAN: PK07BAHL0038098101069701</p>
                <p>Email: <a href="mailto:info@samorganic.co">info@samorganic.co</a></p>
                <p>WhatsApp: <a href="https://wa.me/+923186000209">Chat with us</a></p>
              </div>
            )}
          </div>
          <button type="submit" className="place-order-button" disabled={isLoading}>
            {isLoading ? 'Placing Order...' : 'Place Order'}
          </button>
        </form>
        <div className="order-summary">
          <h2>Order summary</h2>
          {cart.map((item, index) => (
            <div key={index} className="order-summary-item">
              <img src={item.images[0]} alt={item.title} />
              <div>
                <p>{item.title}</p>
                <p>Price: {formatPKR(item.price)}</p>
                <p>Quantity: {item.quantity}</p>
                <p>Total: {formatPKR(item.price * item.quantity)}</p>
              </div>
            </div>
          ))}
          <div className="coupon-section">
            <input
              type="text"
              placeholder="Coupon code"
              value={coupon}
              onChange={handleCouponChange}
            />
            <button onClick={handleCouponApply}>Apply</button>
            {couponMessage && <p>{couponMessage}</p>}
          </div>
          <div className="order-total">
            <p>Subtotal: {formatPKR(cartTotal)}</p>
            {discount > 0 && <p>Discount: -{formatPKR(cartTotal * discount)}</p>}
            {additionalDiscount > 0 && <p>Additional Discount: -{formatPKR(cartTotal * additionalDiscount)}</p>}
            <p>Delivery Charges: {formatPKR(DELIVERY_CHARGES)}</p>
            <p>Total: {formatPKR(totalWithDelivery)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
