// Contact.js

import React, { useState } from 'react'; // Import useState here
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons'; // Add faPaperPlane
import './Contact.css';
import Card from './Card';
import { BASE_URL } from './helper'; 




const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const { name, email, subject, message } = formData;
  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(`${BASE_URL}/api/contact`, formData);
      alert('Message sent successfully');
      setFormData({ name: '', email: '', subject: '', message: '' });
    } catch (err) {
      console.error(err);
      alert('Failed to send message');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div>
      <div className="section-contact">
        <h1>Get In Touch</h1>
      </div>
      <div className="getintouch-container">
        <Card iconComponent={<FontAwesomeIcon icon={faMapMarkerAlt} size="3x" />}
          text="Office Address" paragraph1="Office No. 54 Ayesha Sadeeqa Market" paragraph2="Kasur, Punjab, Pakistan" width='400px' height='270px' />
        <Card iconComponent={<FontAwesomeIcon icon={faPhone} size="3x" />
        } text="Call Us" paragraph1="Billing: 0318-6000209" paragraph2="Support: 0318-6000709" width='400px' height='270px' />
        <Card iconComponent={<FontAwesomeIcon icon={faEnvelope} size="3x" />
        } text="Email" paragraph1= "General: info@samorganic.co" paragraph2= " " width='400px' height='270px' />
      </div>
      <hr /> {/* Horizontal line */}

      <div className='message-us'>
      <h2 className="message-title">
          If you have any Query,
          <span style={{ color: '#28a745' }}> Please</span><br />
          <span style={{ color: '#28a745' }}>Contact Us</span>
        </h2>
        <div className="contact-form-map">
          <div className="contact-form">
            <form onSubmit={onSubmit}>
              <div className="form-group-row">
                <div className="form-group-1">
                  <input type="text" id="name" name="name" placeholder="Your Name" value={name} onChange={onChange}  required />
                  <input type="email" id="email" name="email" placeholder="Your Email" value={email} onChange={onChange} required />
                </div>
                
              </div>
              <div className="form-group">
                <input type="text" id="subject" name="subject" placeholder="Subject" value={subject} onChange={onChange} required />
              </div>
              <div className="form-group">
                <textarea id="message" name="message" placeholder="Message" value={message} onChange={onChange} required></textarea>
              </div>
              <button type="submit" className="send-message-button" disabled={isLoading}>
                {isLoading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </div>
          <div className="map">
            <iframe
              src="https://maps.google.com/maps?q=Shop%20No.%2054%20Ayesha%20Sadeeqa,%20Kasur,%20Punjab,%20Pakistan&t=&z=13&ie=UTF8&iwloc=&output=embed"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Contact;
