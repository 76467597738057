// ViewCartPage.js

import React, { useContext , useEffect } from 'react';
import { CartContext } from './CardContext'; // Ensure correct import path
import { useNavigate } from 'react-router-dom';
import './ViewCartPage.css'; // Create a CSS file for styling

const formatPKR = (amount) => {
  // Function to format PKR amount
  return `PKR ${amount.toFixed(2)}`;
};

const ViewCartPage = () => {
  const { cart, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate(); // Hook for navigation

  // Calculate subtotal
  const subtotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);

  const handleCheckout = () => {
    navigate('/checkout'); // Navigate to the checkout page
  };

  const handleRemove = (index) => {
    removeFromCart(index);
    if (cart.length === 0) {
      navigate('/products'); // Redirect to products if cart is empty after removal
    }
  };

  useEffect(() => {
    // Check if cart is empty on component mount
    if (cart.length === 0) {
      navigate('/products'); // Redirect to home if cart is empty
    }
  }, [cart, navigate]);


  return (
    <div className="view-my-cart">
      <h1>Your Shopping Cart</h1>
      <div className="my-items">
        {cart.map((item, index) => (
          <div key={index} className="my-item">
            <img src={item.images[0]} alt={item.title} />
            <div>
              <p>{item.title}</p>
              <p>{formatPKR(item.price * item.quantity)}</p>
              <p>{item.description}</p>
              <div className="quantity-control">
                <button onClick={() => updateQuantity(index, item.quantity - 1)}>-</button>
                <span>{item.quantity}</span>
                <button onClick={() => updateQuantity(index, item.quantity + 1)}>+</button>
              </div>
              <button onClick={() => handleRemove(index)} className='remove-my-item'>Remove item</button>
            </div>
          </div>
        ))}
      </div>
      <div className="my-cart-bottom-section">
        <div className="subtotal">
          <p>Subtotal: {formatPKR(subtotal)}</p>
          <p>Shipping, taxes, and discounts calculated at checkout</p>
        </div>
        <div className="cart-but">
          <button onClick={handleCheckout} className="my-checkout">Go to Checkout</button>
        </div>
      </div>
    </div>
  );
};

export default ViewCartPage;
