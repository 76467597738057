import React, { useContext , useEffect } from 'react';
import { CartContext } from './CardContext'; // Fixed import
import { Link, useNavigate } from 'react-router-dom';
import './Cart.css';

const formatPKR = (amount) => {
  return `PKR ${amount.toFixed(2)}`;
};


const Cart = ({ isOpen, onClose }) => {
  const { cart, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate(); // Hook for navigation

  // Calculate subtotal
  const subtotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);

  const handleCheckout = () => {
    onClose(); // Close the cart drawer/modal
    navigate('/checkout'); // Navigate to the checkout page
  };
  

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    if (cart.length === 0 && isOpen) {
      navigate('/products');
    }
  }, [cart, isOpen , navigate]);

  return (
    <>
      <div className={`overlay ${isOpen ? 'show' : ''}`} onClick={onClose}></div>
      <div className={`cart ${isOpen ? 'open' : ''}`}>
        <div className="cart-header">
          <h2>Your cart ({cart.length} items)</h2>
          <button onClick={onClose} className="close-cart">X</button>
        </div>
        {cart.length === 0 ? (
          <div className="empty-cart">
            <p>Your cart is empty</p>
            <Link to="/products" onClick={onClose} className="shop-now">Shop Now</Link>
          </div>
        ) : (
          <div>
            <div className="cart-items">
              {cart.map((item, index) => (
                <div key={index} className="cart-item">
                  <img src={item.images[0]} alt={item.title} />
                  <div>
                    <p>{item.title}</p>
                    <p>{formatPKR(item.price)}</p>
                    <p>{"Dried Kasuri Methi is renowned for their exceptional quality, enhancing the flavor of your culinary creations with their unique, slightly bitter taste. Our Kasuri Methi is sourced and processed with utmost care to retain its natural essence and purity."}</p>
                    <div className="quantity-controls">
                      <button onClick={() => updateQuantity(index, item.quantity - 1)}>-</button>
                      <span>{item.quantity}</span>
                      <button onClick={() => updateQuantity(index, item.quantity + 1)}>+</button>
                    </div>
                    <button onClick={() => removeFromCart(index)}>Remove item</button>
                  </div>
                </div>
              ))}
            </div>
            <div className="cart-bottom-section">
              <div className="subtotal">
                <p>Subtotal: {formatPKR(subtotal)}</p>
                <p>Shipping, taxes, and discounts calculated at checkout</p>
              </div>
              <div className="cart-buttons">
                <Link to="/viewcart" className="view-cart" onClick={onClose}>View my cart</Link>
                <button onClick={handleCheckout} className="go-to-checkout">Go to checkout</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Cart;
