import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { CartContext } from './CardContext';
import Cart from './Cart';
import './Navbar.css';

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [cartOpen, setCartOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const { cart } = useContext(CartContext);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMenuToggle = () => {
        setShowMenu(!showMenu);
    };

    const handleCartToggle = () => {
        setCartOpen(!cartOpen);
    };

    return (
        <>
            <nav className={`navbar ${showMenu ? 'responsive' : ''} ${isScrolled ? 'solid' : 'transparent'}`}>
                <div className="navbar-brand">
                    <img src='./logo.webp' alt={"Logo"} />
                </div>
                <div className={`navbar-links ${showMenu ? 'show' : ''}`}>
                    <Link to="/" onClick={() => setShowMenu(false)}>Home</Link>
                    <Link to="/about" onClick={() => setShowMenu(false)}>About</Link>
                    <Link to="/products" onClick={() => setShowMenu(false)}>Products</Link>
                    <div className="dropdown">
                        <button className="dropbtn">Pages</button>
                        <div className="dropdown-content">
                            <Link to="/pages/benefits" onClick={() => setShowMenu(false)}>Benefits</Link>
                            <Link to="/pages/how-to-use" onClick={() => setShowMenu(false)}>How to Use</Link>
                        </div>
                    </div>
                    <Link to="/contact" onClick={() => setShowMenu(false)}>Contact</Link>

                    <button className="cart-icon" onClick={() => setCartOpen(true)}>
                        <FontAwesomeIcon icon={faShoppingCart} />
                        {cart.length > 0 && <span className="cart-count">{cart.length}</span>}
                    </button>
                </div>
                <button className="cart-icon-mobile" onClick={() => setCartOpen(true)}>
                        <FontAwesomeIcon icon={faShoppingCart} />
                        {cart.length > 0 && <span className="cart-count">{cart.length}</span>}
                </button>
                <button className="menu-toggle" onClick={handleMenuToggle}>
                    <FontAwesomeIcon icon={showMenu ? faTimes : faBars} />
                </button>
            </nav>
            <Cart isOpen={cartOpen} onClose={handleCartToggle} />
            <div className={`overlay ${showMenu ? 'show' : ''}`} onClick={handleMenuToggle}></div>
            <div className={`overlay ${cartOpen ? 'show' : ''}`} onClick={handleCartToggle}></div>

        </>
    );
};

export default Navbar;
