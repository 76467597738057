// src/Card.js
import React from 'react';
import './Card.css';

const Card = ({ iconSrc, iconComponent , text, paragraph1 , paragraph2 , width = '400px', height = '220px' }) => {
  return (
    <div className="card" style={{ width, height }}>
      <div className="card-inner">
        <div className="card-content">
          <div className="card-icon">
            {iconComponent ? iconComponent : <img src={iconSrc} alt='icon' />}
          </div>
          <div className="card-text">{text}</div>
          {paragraph1 && <div className="card-paragraph1">{paragraph1}</div>}
          {paragraph2 && <div className="card-paragraph2">{paragraph2}</div>}
        </div>
      </div>
    </div>
  );
};

export default Card;
