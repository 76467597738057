// Footer.js

import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <img src="/logo.webp" alt="Company Logo" className="footer-logo" />
          <p className="footer-description">
          Sam Organic Co. offers premium, organic spices and herbs, prioritizing quality, sustainability, and purity.
          </p>
        </div>
        <div className="footer-section">
          <h3>Get in Touch</h3>
          <p><FontAwesomeIcon icon={faMapMarkerAlt} />  Office No. 54 Ayesha Sadeeqa Market Kasur</p>
          <p><FontAwesomeIcon icon={faPhone} />  0318-6000709-209</p>
          <p><FontAwesomeIcon icon={faEnvelope} />   info@samorganic.co</p>
          <div className="footer-social-icons">
            <a href="https://www.facebook.com/share/KCvBuzjPoW3i4VWr/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://www.instagram.com/samorganic.co?igsh=MXg2bDQwdmFlbGxodw%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://wa.me/+923186000209" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div>
        </div>
        <div className="footer-section">
          <h3>Information</h3>
          <ul className="footer-links">
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/privacypolicy">Privacy Policy</Link></li>
            <li><Link to="/shippingpolicy">Shipping Policy</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 SAM Organic. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
